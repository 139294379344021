import React from 'react';
import { useCheckout } from '@ttg/ui-checkout-lib';

export const CheckoutProviderLayout = ({ children }) => {
	const {
		customHooks: {
			paymentHook: { isPaying, isLoadingInvoice, isOrderProcessed },
		},
	} = useCheckout();
	const blurred = React.useMemo(
		() => isPaying || isLoadingInvoice || isOrderProcessed === false,
		[isPaying, isLoadingInvoice, isOrderProcessed]
	);

	return (
		<div className={`checkout-layout-provider ${blurred && 'blurred'}`}>
			<div className={`checkout-content ${blurred && 'blurred'}`}>{children}</div>
		</div>
	);
};
