import React, {useEffect, useState} from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { useUser } from './UserProvider';
import { checkCookiePresent, setAuthCookie } from '../services/UtilityService';

export const TokenLoginWrapper = ({ children }) => {
    const { loadUser } = useUser();
    const location = useLocation();
    const [internalLoad, setInternalLoad] = useState(true);

    useEffect(() => {
        const cookiePresent = checkCookiePresent(location);

        if (cookiePresent) {
            setAuthCookie(cookiePresent);

            return loadUser().finally(() => {
                navigate(`${location.pathname}${location.hash || ''}`, { replace: true });
                setInternalLoad(false);
            });
        }
        setInternalLoad(false);
    }, []);

    if (internalLoad) {
        return null;
    }

    return children;
};
