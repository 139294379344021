import React from 'react';
import AppRIIcon from '../components/AppRIIcon';
import { useUser } from '../hooks/UserProvider';

const AppRoleBar = () => {
    const { user, switchToOriginalUser } = useUser();

    const handleSwitchBack = () => {
        switchToOriginalUser();
    };

    return (
        <>
            {user?.original_customer && (
                <div className={`permission-level-banner ${user?.original_customer.permission_role}`}>
                    <div className='flex flex-1 flex-col md:flex-row container mx-auto'>
                        <div className='flex flex-col md:flex-row flex-1 items-center justify-center md:justify-start'>
                            <AppRIIcon icon='information-line' className='text-2xl mx-2' />
                            <p className='py-3 text-center md:text-left'>
                                You are now viewing <b>{user.fullName}</b> account as{' '}
                                <b>{user.original_customer.permission_role}</b>.
                            </p>
                        </div>
                        <div
                            className='flex items-center justify-center font-semibold underline cursor-pointer hover:no-underline'
                            onClick={() => handleSwitchBack()}
                        >
                            Switch to My Account
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AppRoleBar;
