import React from 'react';
import { Router } from '@reach/router';
import Login from '../views/client/login';
import NotFoundPage from '../views/404';

import { CommonRoutes, PdfRoutes, ProtectedRoutes } from './routes';
import { AuthContextLayout } from '../hooks/AuthProvider';
import { CheckoutProviderLayout } from "../hooks/CheckoutLayoutProvider";

const loginPath = '/client/login/';

const IndexRoutePages = () => {
	const CommonRoute = ({ component: Component, location, ...rest }) => <Component {...rest} />;

	const ProtectedRoute = ({ component: Component, location, ...rest }) => (
		<AuthContextLayout permission={rest.permission}>
			<CheckoutProviderLayout>
			<Component {...rest} />
			</CheckoutProviderLayout>
		</AuthContextLayout>
	);

	return (
		<Router>
			{CommonRoutes.map((route, key) => {
				const Route = route.component;
				if (!route.children)
					return <CommonRoute key={key} path={route.path} component={route.component} {...route.props} />;

				return (
					<Route key={key} path={route.path} {...route.props} exact>
						{route.children.map((childRoute) => (
							<CommonRoute
								key={childRoute.path}
								path={childRoute.path}
								component={childRoute.component}
								{...route.props}
								{...childRoute.props}
							/>
						))}
					</Route>
				);
			})}

			{PdfRoutes.map((route, key) => {
				const Route = route.component;
				if (!route.children) return <Route key={key} path={route.path} {...route.props} />;

				return (
					<Route key={key} path={route.path} {...route.props} exact>
						{route.children.map((childRoute) => {
							const ChildRoute = childRoute.component;
							return <ChildRoute path={childRoute.path} {...childRoute.props} />;
						})}
					</Route>
				);
			})}
			{ProtectedRoutes.map((route, key) => {
				return <ProtectedRoute key={route.path} path={route.path} component={route.component} permission={route?.permission} />;
			})}

			<CommonRoute path={loginPath} component={Login} />
			<NotFoundPage path='*' />
		</Router>
	);
};

export default IndexRoutePages;
